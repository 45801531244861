import React from 'react'
import ReactDOM from 'react-dom'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './firebase'
import DateProvider from './containers/LeaderboardsList/DateContext'
import SortProvider from './containers/LeaderboardsList/SortContext'
import { getPlayerId } from './auth-token'
import initializeDatadogRUM from './helpers/init-datadog'

declare global {
  interface Window {
    rudderanalytics: {
      track: (
        event: string,
        properties: Record<string, unknown>,
        callback?: () => void
      ) => void
      identify: (playerId: string) => void
    }
  }
}

dayjs.extend(localizedFormat)
dayjs.extend(advancedFormat)

window.analytics.identify(getPlayerId())

window.rudderanalytics.identify(getPlayerId())

ReactDOM.render(
  <React.StrictMode>
    <DateProvider>
      <SortProvider>
        <App />
      </SortProvider>
    </DateProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
