import { MetricFilter, Offer } from '../types/leaderboard'
import {getAppFlavour} from "../auth-token";

const gameNameToIdMap: Record<string, string> = {
  Rummy: 'viK8cufqpSVhR5uw45Q3',
  Poker: 'q2H8QFx8exAbeSf9H5SB',
  Carrom: 'yFrjvZiwlfqohS3tJXXX',
}

export const generateCTA = (metricFilters: MetricFilter[]): string => {
  let url = 'https://www.getmega.com/tournament?' // default url
  if (getAppFlavour() === 'rummy') {
    url = 'https://www.getmegarummy.com/tournament?'
  }

  let qs: Record<string, any> = {
    gameId: 'q2H8QFx8exAbeSf9H5SB',
  }

  metricFilters.forEach(m => {
    if (m.metric_field.toString() === 'GameName') {
      const gameName = m.value_range?.string_value || 'Poker'
      if (!!gameNameToIdMap[gameName]) {
        qs['gameId'] = gameNameToIdMap[gameName]
      }
    }

    if (m.metric_field.toString() === 'Bigblind') {
      qs['minBigBlind'] = m.value_range.number_min
      qs['maxBigBlind'] = m.value_range.number_max
    }
  })

  url = url + buildQuery(qs)

  return url
}

const buildQuery = function (data: any) {
  // If the data is already a string, return it as-is
  if (typeof data === 'string') return data

  // Create a query array to hold the key/value pairs
  var query = []

  // Loop through the data object
  for (var key in data) {
    if (data.hasOwnProperty(key)) {
      // Encode each key and value, concatenate them into a string, and push them to the array
      query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    }
  }

  // Join each item in the array with a `&` and return the resulting string
  return query.join('&')
}

export const generateAddMoneyCTA = (
  balance: number,
  entryFee: number,
  lbId: string,
  offer?: Offer
): string => {
  let discount = 0
  if (!!offer) {
    discount = Math.max(offer.reward.Amount, 0)
  }

  const amountToPay = Math.ceil(entryFee - discount - balance)
  const roundUp100 = Math.ceil(Math.ceil(amountToPay / 100) * 100)
  const p1 = Math.ceil(roundUp100 * 1.1)
  const p2 = Math.ceil(roundUp100 * 1.2)
  const p3 = Math.ceil(roundUp100 * 1.3)

  const isRummyFlavor = getAppFlavour() === 'rummy'

  if (amountToPay > 0) {
    let walletUrl = `https://www.getmega.com/wallet/addCash`
    if (isRummyFlavor) {
      walletUrl = `https://www.getmegarummy.com/wallet/addCash`
    }

    let successCallbackLink = `https://www.${isRummyFlavor ? 'getmegarummy.com' : 'getmega.com'}.com/internalWebview/Mega%20Champ/${process.env.REACT_APP_USER_URL}leaderboard-details/${lbId}/purchaseCard`
    return `${walletUrl}?defaultAmount=${roundUp100}&minDepositAmount=${amountToPay}&successCallbackLink=${successCallbackLink}/&pillOneValue=${p1}&pillTwoValue=${p2}&pillThreeValue=${p3}`
  } else {
    return '#'
  }
}

export const generateImageUrl = (image_url: string) => {
  const dpr = window.devicePixelRatio
  const width = window.innerWidth - 32
  return `${process.env.REACT_APP_IMAGE_KIT_URL}${image_url}?tr=w-${width},dpr-${dpr},pr-true`
}

export const generateProfileUrl = (profile_url: string) => {
  const width = 60
  return `${process.env.REACT_APP_IMAGE_KIT_URL}${profile_url}?tr=w-${width},dpr-1,pr-true,di-default_user_dp.png`
}

export default generateCTA
