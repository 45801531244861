import React, { useEffect, useState, useCallback } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import dayjs, { Dayjs } from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import styles from './LeaderboardsList.module.scss'
import WeekCalendar from '../../components/WeekCalendar/WeekCalendar'
import NotJoinedLeaderboards from './NotJoinedLeaderboards'
import { Leaderboard } from '../../types/leaderboard'
import { getLeaderboards } from './api'
import { useDate } from './DateContext'
import { getAppFlavour, getPlayerId } from '../../auth-token'
import Prize from '../../assets/images/prize.png'
import PropertyBannerList from '../Properties/PropertyBannerList'
import Arrow from '../../assets/images/arrow-right.png'

type Props = RouteComponentProps<{
  path: string
  filterParams: string
}>

const LeaderboardsList: React.FC<Props> = props => {
  dayjs.extend(isBetween)

  const flavor = getAppFlavour()

  const [leaderboards, setLeaderboards] = useState<Leaderboard[]>([])
  const [joinedLbs] = useState<Leaderboard[]>([])
  const [loadingLbs, setLoadingLbs] = useState(false)
  const { date, setDate } = useDate()!
  const [, setLbError] = useState('')

  const fetchLeaderboards = async (d: Dayjs): Promise<Leaderboard[]> => {
    setLoadingLbs(true)
    const { leaderboards: lbs, error } = await getLeaderboards(d.format())
    setLeaderboards(lbs)
    setLbError(error)
    setLoadingLbs(false)

    return lbs
  }

  const fetchLbs = useCallback(async (d: Dayjs): Promise<void> => {
    await Promise.all([fetchLeaderboards(d)]).then(values => {
      return values
    })
  }, [])

  useEffect(() => {
    if (!!props.filterParams) {
      const filterParams = JSON.parse(props.filterParams)

      if (!!filterParams?.filters?.date) {
        let selectedDate = dayjs(filterParams.filters.date)

        let now = dayjs(dayjs().startOf('day'))

        if (
          selectedDate.isBetween(
            now.subtract(1, 'day'),
            now.add(12, 'day'),
            null,
            '[]'
          )
        ) {
          setDate(selectedDate)
        }
      }
    }
  }, [props.filterParams, setDate])

  useEffect(() => {
    fetchLbs(date)
  }, [date, fetchLbs])

  // init playerId
  useEffect(() => {
    const playerId = getPlayerId()
    //@ts-ignore
    clevertap.profile.push({
      Site: {
        Identity: playerId,
      },
    })
  }, [])

  // Example filter params
  // {"filters": {"entryType": "Free"}}
  // Example deeplink for staging app
  // https://www.getmega.com/internalWebview/Mega Champ/https://staging-ms-user.web.app/{"filters": {"entryType": ["Free"]}}

  if (!!props.filterParams) {
    const filterParams = JSON.parse(props.filterParams)
    window.sessionStorage.setItem(
      'filters',
      JSON.stringify(filterParams.filters)
    )
    navigate('/', { replace: true })
  }

  return (
    <div id={styles.LeaderboardsList}>
      <div className={styles.PageTitle}>All Tournaments</div>
      {flavor !== 'rummy' && <PropertyBannerList />}

      <div
        className={styles.myLeaderboards}
        onClick={() => {
          navigate('/my-leaderboards')
        }}
      >
        <div className={styles.textContainer}>
          <img className={styles.leftImage} src={Prize} alt="prizeIcon" />
          <p className={styles.text}>My Leaderboards</p>
        </div>
        <div className={styles.arrowContainer}>
          <img className={styles.arrow} src={Arrow} alt="arrow" />
        </div>
      </div>

      <div style={{ height: '1px', background: 'rgba(255, 255, 255, 0.1)' }} />

      <div style={{ padding: '16px' }}>
        <WeekCalendar
          date={date}
          onDayClick={(d): void => {
            setDate(d)
          }}
        />
      </div>

      <div>
        <NotJoinedLeaderboards
          leaderboards={leaderboards}
          myLeaderboards={joinedLbs}
          loading={loadingLbs}
          date={date}
        />
      </div>
    </div>
  )
}

export default LeaderboardsList
